import { ProduitType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { ProduitData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/produit/index.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageProduitsUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, produitId },
}) => (
  <TemplateEspace
    collectionName="produits"
    docId={produitId}
    espace={espace}
    user={user}
  >
    <CMSView>
      <Form<ProduitType>
        displayHidden
        displayView
        docId={produitId}
        itemPathnamePrefix={`/espaces/${espaceId}/produits/`}
        model={new ProduitData({ espaceId, params })}
        name="produit"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageProduitsUpdate);
